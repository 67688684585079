import { Link } from "gatsby";
import React, { useState } from "react";

export const SECTION_LINK_COVER_IMAGE_CLASSES = "rounded-4xl";

// This value is highly coupled with the max width of the Container component
export const SECTION_LINK_COVER_IMAGE_MAX_WIDTH = 850;

const SectionLinkCover = ({ title, image, link }) => {
    const [showBlur, setBlur] = useState(false);

    return (
        <Link
            to={link}
            className="relative"
            role="link"
            onMouseOver={() => setBlur(true)}
            onFocusCapture={() => setBlur(true)}
            onMouseLeave={() => setBlur(false)}
            onBlur={() => setBlur(false)}
        >
            <div className={`${showBlur ? "blur-sm" : ""}`}>{image}</div>
            <span className="absolute top-1/2 -translate-y-1/2 w-full text-center text-5xl md:text-8xl text-white uppercase">
                {title}
            </span>
        </Link>
    );
};

export default SectionLinkCover;
