import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import {
    MdOutlineArrowBackIosNew,
    MdOutlineArrowForwardIos,
} from "react-icons/md";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import BookingEngineWidget from "./BookingEngineWidget";
import Container from "./Container";
import ReviewBadges from "./ReviewBadges";

const HeroCarousel = () => {
    const { t } = useTranslation();

    // Define common properties reusable for each image of carousel
    const sharedCarouselImageProperties = {
        className: "rounded-b-4xl",
        layout: "fullWidth",
        style: {
            // Full-height, minus 80px of navbar and 80px of bottom space for UI/UX
            height: "calc(100vh - 80px - 80px)",
        },
        transformOptions: {
            fit: "cover",
        },
    };

    const images = [
        <StaticImage
            key={0}
            src="../../assets/images/insegna-hotel.jpg"
            alt="Insegna"
            {...sharedCarouselImageProperties}
        />,
        <StaticImage
            key={1}
            src="../../assets/images/terrazzo-vista-basilica.jpg"
            alt="Terrazzo vista basilica"
            {...sharedCarouselImageProperties}
        />,
        <StaticImage
            key={2}
            src="../../assets/images/camere/vista-basilica.jpg"
            alt="Camera vista basilica"
            {...sharedCarouselImageProperties}
        />,
        <StaticImage
            key={3}
            src="../../assets/images/sala-pranzo/panoramica.jpg"
            alt="Panoramica sala da pranzo"
            {...sharedCarouselImageProperties}
        />,
    ];

    return (
        <div>
            <div className="absolute z-10 w-full pt-4 flex justify-center">
                <ReviewBadges />
            </div>
            <Carousel
                autoPlay={true}
                emulateTouch={false}
                infiniteLoop={true}
                interval={3000}
                preventMovementUntilSwipeScrollTolerance={true} // Allow to vertical scroll the page in mobile
                renderArrowPrev={(clickHandler) => (
                    <button
                        className="absolute z-10 top-1/2 left-4"
                        onClick={clickHandler}
                        style={{ transform: "translateY(-16px)" }} // Compensate half of 32px of the icon
                        aria-label={t("previous")}
                    >
                        <MdOutlineArrowBackIosNew
                            size={32}
                            className="text-white"
                        />
                    </button>
                )}
                renderArrowNext={(clickHandler) => (
                    <button
                        className="absolute z-10 top-1/2 right-4"
                        onClick={clickHandler}
                        style={{ transform: "translateY(-16px)" }} // Compensate half of 32px of the icon
                        aria-label={t("next")}
                    >
                        <MdOutlineArrowForwardIos
                            size={32}
                            className="text-white"
                        />
                    </button>
                )}
                showIndicators={false}
                showStatus={false}
                showThumbs={false}
                swipeScrollTolerance={30} // Prevent to change image in case of vertical scrolling
                transitionTime={700}
            >
                {images}
            </Carousel>
            <Container customStyle={{ marginTop: 0 }}>
                <BookingEngineWidget />
            </Container>
        </div>
    );
};

export default HeroCarousel;
