import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import { container } from "./bookingEngineWidget.module.css";
import Button from "./Button";

const BookingEngineWidget = () => {
    const { t, i18n } = useTranslation();
    if (!i18n.hasLoadedNamespace("component-BookingEngineWidget")) {
        throw new Error(
            "Cannot use BookingEngineWidget without component-BookingEngineWidget namespace"
        );
    }

    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    const formattedToday = today.toISOString().slice(0, 10);
    const formattedTomorrow = tomorrow.toISOString().slice(0, 10);

    const onSubmit = (event) => {
        event.preventDefault();

        const form = event.target;

        // Prevent to submit the form if is not valid
        if (!form.checkValidity()) {
            return;
        }

        // Since the Slope endpoint requires dates in format dd/mm/yyyy we need to reverse the HTML5 format.
        // Note that input elements must be converted to generic text otherwise the browser will not accept the new value.
        for (const input of form) {
            if (input.type === "date") {
                input.type = "text";
                input.value = input.value.split("-").reverse().join("/");
            }
        }

        form.submit();
    };

    return (
        <div
            className={`${container} z-10 py-4 px-8 md:px-4 flex self-center md:self-auto items-center bg-white rounded-4xl shadow-darkBrown shadow-md`}
        >
            {/**  */}
            <form
                className="w-full flex flex-col md:flex-row items-center justify-between"
                action={`https://booking.slope.it/widgets/search/c8e1dbe3-ddea-4ced-a93e-27bb95d256c0/${i18n.language}`}
                method="POST"
                onSubmit={onSubmit}
            >
                <div className="flex flex-col md:flex-row">
                    <label className="flex flex-col pb-4 md:pb-0 md:pr-4">
                        <b>{t("component-BookingEngineWidget:arrival")}</b>
                        <input
                            type="date"
                            required
                            name="reservation[stay][arrival]"
                            min={formattedToday}
                            defaultValue={formattedToday}
                        />
                    </label>
                    <label className="flex flex-col pb-4 md:pb-0 md:pr-4">
                        <b>{t("component-BookingEngineWidget:departure")}</b>
                        <input
                            type="date"
                            required
                            name="reservation[stay][departure]"
                            defaultValue={formattedTomorrow}
                        />
                    </label>
                    <label className="flex flex-col pb-4 md:pb-0">
                        <b>{t("component-BookingEngineWidget:guests")}</b>
                        <input
                            type="number"
                            required
                            name="reservation[guests][adults]"
                            min="1"
                            max="5"
                            defaultValue="2"
                        />
                    </label>
                </div>
                <div>
                    <Button
                        text={t("component-BookingEngineWidget:book")}
                        type="submit"
                    />
                </div>
            </form>
        </div>
    );
};

export default BookingEngineWidget;
