import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import Button from "../components/Button";
import Container from "../components/Container";
import HeroCarousel from "../components/HeroCarousel";
import Layout from "../components/Layout";
import LinkButton from "../components/LinkButton";
import SectionLinkCover, {
    SECTION_LINK_COVER_IMAGE_CLASSES,
    SECTION_LINK_COVER_IMAGE_MAX_WIDTH,
} from "../components/SectionLinkCover";
import Seo from "../components/Seo";
import Separator from "../components/Separator";

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: {
                    in: [
                        "common"
                        "page-index"
                        "component-BookingEngineWidget"
                    ]
                }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default function Home() {
    const { t } = useTranslation();

    return (
        <Layout>
            <Seo title="Home" />

            {/** Carousel, votes and reservation widget container */}
            <HeroCarousel />

            {/** Page sections: lead to specific subpages */}
            <Container>
                <h1>{t("page-index:whoWeAre:title")}</h1>
                <p>{t("page-index:whoWeAre:description1")}</p>
                <p>{t("page-index:whoWeAre:description2")}</p>

                <Separator />

                <SectionLinkCover
                    title={t("page-index:sections:theRooms")}
                    image={
                        <StaticImage
                            src="../../assets/images/camere/parete-naturale.jpg"
                            alt={t("page-index:sections:theRooms")}
                            className={SECTION_LINK_COVER_IMAGE_CLASSES}
                            aspectRatio={21 / 9}
                            width={SECTION_LINK_COVER_IMAGE_MAX_WIDTH}
                            transformOptions={{ fit: "cover" }}
                        />
                    }
                    link="/camere"
                />
                <p className="mt-4">
                    {t("page-index:sections:roomsDescription")}
                </p>
                <div className="text-center">
                    <a href="https://booking.slope.it/c8e1dbe3-ddea-4ced-a93e-27bb95d256c0">
                        <Button
                            text={t(
                                "page-index:sections:exploreRatesAndAvailability"
                            )}
                        />
                    </a>
                </div>

                <Separator />

                <SectionLinkCover
                    title={t("page-index:sections:theRestaurant")}
                    image={
                        <StaticImage
                            src="../../assets/images/sala-pranzo/panoramica.jpg"
                            alt={t("page-index:sections:theRestaurant")}
                            className={SECTION_LINK_COVER_IMAGE_CLASSES}
                            aspectRatio={21 / 9}
                            width={SECTION_LINK_COVER_IMAGE_MAX_WIDTH}
                            transformOptions={{ fit: "cover" }}
                        />
                    }
                    link="/ristorante"
                />
                <p className="mt-4">
                    {t("page-index:sections:restaurantDescription")}
                </p>
                <div className="text-center">
                    <LinkButton
                        to="/ristorante/menu"
                        text={t("page-index:sections:discoverTheMenu")}
                    />
                </div>

                <Separator />

                <SectionLinkCover
                    title={t("page-index:sections:reviews")}
                    image={
                        <StaticImage
                            src="../../assets/images/recensioni.jpg"
                            alt={t("page-index:sections:reviews")}
                            className={SECTION_LINK_COVER_IMAGE_CLASSES}
                            aspectRatio={21 / 9}
                            width={SECTION_LINK_COVER_IMAGE_MAX_WIDTH}
                            transformOptions={{ fit: "cover" }}
                        />
                    }
                    link="/recensioni"
                />

                <Separator />

                <SectionLinkCover
                    title={t("page-index:sections:faq")}
                    image={
                        <StaticImage
                            src="../../assets/images/campanello.jpg"
                            alt={t("page-index:sections:faq")}
                            className={SECTION_LINK_COVER_IMAGE_CLASSES}
                            aspectRatio={21 / 9}
                            width={SECTION_LINK_COVER_IMAGE_MAX_WIDTH}
                            transformOptions={{ fit: "cover" }}
                        />
                    }
                    link="/faq"
                />

                <Separator />

                <SectionLinkCover
                    title={t("page-index:sections:whereWeAre")}
                    image={
                        <StaticImage
                            src="../../assets/images/assisi-e-papaveri.jpg"
                            alt={t("page-index:sections:whereWeAre")}
                            className={SECTION_LINK_COVER_IMAGE_CLASSES}
                            aspectRatio={21 / 9}
                            width={SECTION_LINK_COVER_IMAGE_MAX_WIDTH}
                            transformOptions={{ fit: "cover" }}
                        />
                    }
                    link="/dove-siamo"
                />

                <Separator />

                <SectionLinkCover
                    title={t("page-index:sections:contacts")}
                    image={
                        <StaticImage
                            src="../../assets/images/contattaci.jpg"
                            alt={t("page-index:sections:contacts")}
                            className={SECTION_LINK_COVER_IMAGE_CLASSES}
                            aspectRatio={21 / 9}
                            width={SECTION_LINK_COVER_IMAGE_MAX_WIDTH}
                            transformOptions={{ fit: "cover" }}
                        />
                    }
                    link="/contatti"
                />

                <div className="mb-4"></div>
            </Container>
        </Layout>
    );
}
